import React,{Component} from 'react';
import {Link} from 'react-router-dom';

class HeaderMenu extends Component{
	render(){
		return(
			<>
				<ul className="nav navbar-nav">
					<li className="active"><Link to={'#'}><span className="ti-home"></span> </Link>
						{/* <ul className="sub-menu">
							<li><Link to={'./'} className="dez-page">Home 1</Link></li>
							<li><Link to={'./index-2'} className="dez-page">Home 2</Link></li>
							<li><Link to={'./index-3'} className="dez-page">Home 3</Link></li>
							<li><Link to={'./index-4'} className="dez-page">Home 4</Link></li>
							<li><Link to={'./index-5'} className="dez-page">Home 5</Link></li>
							<li><Link to={'./index-6'} className="dez-page">Home 6 <span className="new-page">New</span></Link></li>
							<li><Link to={'./index-7'} className="dez-page">Home 7 <span className="new-page">New</span></Link></li>
						</ul> */}
					</li>
				
					<li><a href='#projectsTab'  className="dez-page">Our Projects</a></li>

					<li><a href='#contact' className="dez-page">Contact Us</a></li>
					{/* <li><Link to={'./about-1'} className="dez-page">About Us</Link>
					
					</li> */}
				
		
				</ul>
			</>
		)
	}
}
export default HeaderMenu;