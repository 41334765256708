import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import Header6 from "./../Layout/Header6";
import Footer2 from "./../Layout/Footer1";
import Index4Tab from "./../Element/Index4Tab";
import ReviewsCarousel from "./../Element/ReviewsCarousel";
import { VideoPopup2 } from "./../Element/VideoPopup";

//Images..
import bgimg from "./../../images/main-slider/slide6.jpg";
import bg16 from "./../../images/background/bg16.jpg";
import about1 from "./../../images/about/pic9.jpg";
import bg1 from "./../../images/background/bg1.jpg";
import gallery10 from "./../../images/gallery/pic10.jpg";
import gallery11 from "./../../images/gallery/pic11.jpg";
import gallery12 from "./../../images/gallery/pic12.jpg";
import gallery13 from "./../../images/gallery/pic13.jpg";
import gallery14 from "./../../images/gallery/pic14.jpg";
import gallery15 from "./../../images/gallery/pic15.jpg";
import iqra from "./../../images/gallery/iqra.jpg";
import bgslider9 from "./../../images/main-slider/slide9.jpg";
import slider9 from "./../../images/main-slider/slide9.png";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
//Light Gallery on icon click
const Iconimage = (props) => {
  const { openLightbox } = useLightbox();
  return (
    <Link
      to={"#"}
      onClick={() => openLightbox(props.imageToOpen)}
      className="mfp-link"
    >
      <i className="ti-fullscreen icon-bx-xs"></i>
    </Link>
  );
};

class Index4 extends Component {
  componentDidMount() {
    var i = 0;

    // Placeholder Animation Start
    var inputSelector = document.querySelectorAll("input, textarea");

    for (i = 0; i < inputSelector.length; i++) {
      inputSelector[i].addEventListener("focus", function (event) {
        return this.parentElement.parentElement.classList.add("focused");
      });
    }

    for (i = 0; i < inputSelector.length; i++) {
      inputSelector[i].addEventListener("blur", function (event) {
        var inputValue = this.value;
        if (inputValue === "") {
          this.parentElement.parentElement.classList.remove("filled");
          this.parentElement.parentElement.classList.remove("focused");
        } else {
          this.parentElement.parentElement.classList.add("filled");
        }
      });
    }
  }
  render() {
    return (
      <>
        <Header6 />
        <div className="page-content bg-white rubik">
          <div
            className="home-banner-2"
            style={{ backgroundImage: "url(" + bgslider9 + ")" }}
          >
     
            <div className="container">
              <div className="home-bnr-inner-2 row align-items-center">
                <div className="home-bnr-content-2 col-md-6">
                  {/* <h4 className="sub-title">Welcome</h4> */}
                  <h2 className="dz-title text-primary">
                    <span>Welcome to</span>
                    <br />
                    <strong>Warsan Group</strong>
                  </h2>
               <p>
                    A formation of companies under the umberella of Warsan Group <br />to provide expertese in production, management and marketing 
                 
                  </p> 
                  {/* <Link to={"#"} className="site-button button-md radius-xl">
                    Explore Now
                  </Link> */}
                </div>
                <div className="col-md-6">
                  <div className="curve-img">
                    <img src={slider9} alt="" className="slide-img-curve" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- contact area --> */}
          <div className="row">
            <div className="col-md-12 col-lg-12 text-center section-head">
              <h2 className="font-weight-700 max-w700 m-auto">Our Projects</h2>
              <p className="m-b0">
                
              </p>
            </div>
          </div>
          <div className="content-block" id="projectsTab">
            {/* <!-- About Us --> */}
            <Carousel
              autoPlay
              showThumbs={false}
              showArrows={true}
              infiniteLoop
              showStatus={false}
              emulateTouch
              interval={2000}
            >
              <div className="section-full content-inner about-progress">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <h2 className="text-uppercase">
                        <span className="font-weight-300">IQRA Studio</span>
                      </h2>
                      <p>
                        Introducing state-of-the-art headphones designed to
                        revolutionize the way you listen to and recite Q
                      </p>{" "}
                      <a
                        href="https://iqrastudio.com/"
                        className="site-button radius-xl button-md"
                      >
                        Visit Website
                      </a>
                    </div>
                    <div className="col-md-12 col-lg-6 d-flex ">
                      <div className="img-half-bx align-items-stretch">
                        <img
                          src={
                            iqra
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-full content-inner about-progress">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <h2 className="text-uppercase">
                        <span className="font-weight-300">Zahaar.com</span>
                      </h2>

                      <p>
                        Zahaar was created to be your online one-stop-shop for
                        niche fragrances in the UAE and eventually, the rest of
                        the Middle-East. Our goal is to completely change the
                        way people experience niche fragrances online and we
                        will be revealing many surprises throughout the year.
                      </p>
                      <a
                        href="https://zahaar.com/"
                        className="site-button radius-xl button-md"
                      >
                        Visit Website
                      </a>
                    </div>
                    <div className="col-md-12 col-lg-6 d-flex ">
                      <div className="img-half-bx align-items-stretch">
                        <img
                          src={
                            "//cdn.shopify.com/s/files/1/0364/0619/8407/products/BannerMobilev1-rev_cef20b3a-5082-4345-a782-d06de957627d.jpg?v=1663490903"
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-full content-inner about-progress">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <h2 className="text-uppercase">
                        <span className="font-weight-300">Oogo Pay</span>
                      </h2>

                      <p>
                        Initiate contactless payments with just a tap of a
                        button and receive payments instantly from your
                        customers. Our platform allows you to accept payments
                        in-person or by sharing a payment link.
                      </p>
                      <a
                        href="https://oogopay.com/"
                        className="site-button radius-xl button-md"
                      >
                        Visit Website
                      </a>
                    </div>
                    <div className="col-md-12 col-lg-6 d-flex ">
                      <div className="img-half-bx align-items-stretch">
                        <img
                          src={
                            "https://oogopay.com/comming_soon/images/Edition.png"
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-full content-inner about-progress">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <h2 className="text-uppercase">
                        <span className="font-weight-300">Yabee.com</span>
                      </h2>

                      <p>
                        Starting an online store does not have to be difficult
                        or expensive. With Yabee, you can set a beautiful
                        ecommerce website for FREE in a matter minutes. No
                        experience required. It’s a simple as opening a Facebook
                        or Instagram account.
                      </p>
                      <a
                        href="https://yabee.me/"
                        className="site-button radius-xl button-md"
                      >
                        Visit Website
                      </a>
                    </div>
                    <div className="col-md-12 col-lg-6 d-flex ">
                      <div className="img-half-bx align-items-stretch">
                        <img
                          src={"https://yabee.me/assets/images/bg-home.jpg"}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
            {/* <!-- About Us End --> */}
            {/* <!-- Tabs End --> */}
            {/* <div className="section-full content-inner-2 tab-bx br-top" id='projectsTab'>
                            <div className="container">
                                <div className="">
                                    <Index4Tab />
                                </div>
                            </div>
                        </div> */}
            {/* <!-- Testimonial End --> */}
            {/* <!-- Testimonial --> */}
            <div
              className="section-full content-inner-2 bg-img-fix overlay-primary gradient testimonial-curv-bx"
              style={{ backgroundImage: "url(" + bg1 + ")" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8">
                    <div className="section-head text-center">
                      <h2 className="text-uppercase">
                        <span className="font-weight-300">Happy customers</span>{" "}
                        <br /> Our Reviews
                      </h2>
                      <h5 className="font-weight-300 text-gray-dark">
                        “It is not the employer who pays the wages. Employers
                        only handle the money… It is the customer who pays the
                        wages.” – Henry Ford
                      </h5>
                    </div>
                    <ReviewsCarousel />
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Testimonial End --> */}
            {/* <!-- Pricing Table --> */}

            {/* <!-- Pricing Table End --> */}
            {/* <!-- Pricing Table --> */}
            <div className="section-full content-inner bg-white workspace-bx mfp-gallery">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-head text-center">
                      <h2 className="text-uppercase ">
                        <span className="font-weight-300">
                          Check our Gallery
                        </span>{" "}
                        {/* <br /> */}
                        {/* Our Product */}
                      </h2>
                    </div>
                  </div>
                </div>
                <SimpleReactLightbox>
                  <SRLWrapper>
                    {" "}
                    <Tabs
                      defaultActiveKey="home"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="home" title="Zahaar">
                        {" "}
                        <div className="row">
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="dlab-box portfolio-box m-b30">
                              <div
                                className="dlab-media dlab-img-effect dlab-img-overlay1"
                                data-tilt=""
                              >
                                {" "}
                                <img
                                  src={
                                    "//cdn.shopify.com/s/files/1/0364/0619/8407/products/HindOudSupreme_800x.jpg?v=1592297979"
                                  }
                                  alt=""
                                />
                                <div className="overlay-bx">
                                  <div className="overlay-icon text-white">
                                    <h5>Zahaar</h5>
                                    <p className="m-b10">
                                      Experience Niche Fragrances at Zahaar
                                    </p>
                                    <Iconimage />
                                    <a
                                      href="https://www.zahaar.com/"
                                      target="bank"
                                    >
                                      <i className="ti-arrow-top-right icon-bx-xs"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="dlab-box portfolio-box m-b30">
                                  <div
                                    className="dlab-media dlab-img-effect dlab-img-overlay1"
                                    data-tilt=""
                                  >
                                    {" "}
                                    <img
                                      src={
                                        "//cdn.shopify.com/s/files/1/0364/0619/8407/products/Discovery_Set_-_Best_Sellers_82d83fc8-f98a-41a1-b384-36cf5e8b4c72_800x.jpg?v=1615119690"
                                      }
                                      alt=""
                                    />
                                    <div className="overlay-bx">
                                      <div className="overlay-icon text-white">
                                        <h5>Zahaar</h5>
                                        <p className="m-b10">
                                          Experience Niche Fragrances at Zahaar
                                        </p>
                                        <Iconimage />
                                        <a
                                          href="https://www.zahaar.com/"
                                          target="bank"
                                        >
                                          <i className="ti-arrow-top-right icon-bx-xs"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="dlab-box portfolio-box m-b30">
                                  <div
                                    className="dlab-media dlab-img-effect dlab-img-overlay1"
                                    data-tilt=""
                                  >
                                    {" "}
                                    <img
                                      src={
                                        "https://cdn.shopify.com/s/files/1/0364/0619/8407/products/GiftSetProductv2_800x.jpg?v=1608724330"
                                      }
                                      alt=""
                                    />
                                    <div className="overlay-bx">
                                      <div className="overlay-icon text-white">
                                        <h5>Zahaar</h5>
                                        <p className="m-b10">
                                          Experience Niche Fragrances at Zahaar
                                        </p>
                                        <Iconimage />
                                        <a
                                          href="https://www.zahaar.com/"
                                          target="bank"
                                        >
                                          <i className="ti-arrow-top-right icon-bx-xs"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="dlab-box portfolio-box m-b30">
                                  <div
                                    className="dlab-media dlab-img-effect dlab-img-overlay1"
                                    data-tilt=""
                                  >
                                    {" "}
                                    <img
                                      src={
                                        "//cdn.shopify.com/s/files/1/0364/0619/8407/products/BannerMobilev1-rev_cef20b3a-5082-4345-a782-d06de957627d.jpg?v=1663490903"
                                      }
                                      alt=""
                                    />
                                    <div className="overlay-bx">
                                      <div className="overlay-icon text-white">
                                        <h5>Zahaar</h5>
                                        <p className="m-b10">
                                          Experience Niche Fragrances at Zahaar
                                        </p>
                                        <Iconimage />
                                        <a
                                          href="https://www.zahaar.com/"
                                          target="bank"
                                        >
                                          <i className="ti-arrow-top-right icon-bx-xs"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="iqra" title="Iqra Studio">
                        {" "}
                        <div className="row">
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="dlab-box portfolio-box m-b30">
                              <div
                                className="dlab-media dlab-img-effect dlab-img-overlay1"
                                data-tilt=""
                              >
                                {" "}
                                <img
                                  src={
                                    iqra
                                  }
                                  alt=""
                                />
                                <div className="overlay-bx">
                                  <div className="overlay-icon text-white">
                                    <h5>Iqra Studio</h5>
                                    <p className="m-b10">
                                     Coming Soon
                                    </p>
                                    <Iconimage />
                                    <a
                                      href="https://www.iqrastudio.com/"
                                      target="bank"
                                    >
                                      <i className="ti-arrow-top-right icon-bx-xs"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="dlab-box portfolio-box m-b30">
                                  <div
                                    className="dlab-media dlab-img-effect dlab-img-overlay1"
                                    data-tilt=""
                                  >
                                    {" "}
                                    <img
                                      src={
                                        " http://aqbmanagement.com/iqra/wp-content/uploads/2022/05/2-3-265x300.jpg"
                                      }
                                      alt=""
                                    />
                                    <div className="overlay-bx">
                                      <div className="overlay-icon text-white">
                                        <h5>Iqra Studio</h5>
                                        <p className="m-b10">
                                        Coming Soon
                                        </p>
                                        <Iconimage />
                                        <a
                                          href="https://www.iqrastudio.com/"
                                          target="bank"
                                        >
                                          <i className="ti-arrow-top-right icon-bx-xs"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="dlab-box portfolio-box m-b30">
                                  <div
                                    className="dlab-media dlab-img-effect dlab-img-overlay1"
                                    data-tilt=""
                                  >
                                    {" "}
                                    <img
                                      src={
                                        "http://aqbmanagement.com/iqra/wp-content/uploads/2022/05/black-variable-2.jpg"
                                      }
                                      alt=""
                                    />
                                    <div className="overlay-bx">
                                      <div className="overlay-icon text-white">
                                        <h5>Iqra Studio</h5>
                                        <p className="m-b10">
                                          Coming Soon
                                        </p>
                                        <Iconimage />
                                        <a
                                          href="https://www.iqrastudio.com/"
                                          target="bank"
                                        >
                                          <i className="ti-arrow-top-right icon-bx-xs"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="dlab-box portfolio-box m-b30">
                                  <div
                                    className="dlab-media dlab-img-effect dlab-img-overlay1"
                                    data-tilt=""
                                  >
                                    {" "}
                                    <img
                                      src={
                                        "http://aqbmanagement.com/iqra/wp-content/uploads/2022/05/box-1.jpg"
                                      }
                                      alt=""
                                    />
                                    <div className="overlay-bx">
                                      <div className="overlay-icon text-white">
                                        <h5>Zahaar</h5>
                                        <p className="m-b10">
                                          Experience Niche Fragrances at Zahaar
                                        </p>
                                        <Iconimage />
                                        <a
                                          href="https://www.iqrastudio.com/"
                                          target="bank"
                                        >
                                          <i className="ti-arrow-top-right icon-bx-xs"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </Tab>
                      <Tab eventKey="oogopay" title="Oogo Pay">
                        {" "}
                        <div className="row">
                        
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                              <div className="col-lg-3 col-md-3 col-sm-6">
                                <div className="dlab-box portfolio-box m-b30">
                                  <div
                                    className="dlab-media dlab-img-effect dlab-img-overlay1"
                                    data-tilt=""
                                  >
                                    {" "}
                                    <img
                                      src={
                                        "https://play-lh.googleusercontent.com/ZZ7qqv1Kr6_NjOyl1jEFz0a0NLzk_MasZ6yHLdH28iZJ0UzY0MkaMkFhBmXJS6Y1PI1H=w2560-h1440-rw"
                                      }
                                      alt=""
                                    />
                                    <div className="overlay-bx">
                                      <div className="overlay-icon text-white">
                                        <h5>Oogo Pay</h5>
                                        <p className="m-b10">
                                        {/* Coming Soon */}
                                        </p>
                                        <Iconimage />
                                        <a
                                          href="https://www.oogopay.com/"
                                          target="bank"
                                        >
                                          <i className="ti-arrow-top-right icon-bx-xs"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                      
                              <div className="col-lg-3 col-md-3 col-sm-6">
                                <div className="dlab-box portfolio-box m-b30">
                                  <div
                                    className="dlab-media dlab-img-effect dlab-img-overlay1"
                                    data-tilt=""
                                  >
                                    {" "}
                                    <img
                                      src={
                                        "https://play-lh.googleusercontent.com/lyMwGOgqBLWBHrjJk4vol3fzeKUWPQtGAgMW9PaIGmTMF4RppmqXkE8tqQpHB7nexj0=w5120-h2880-rw"
                                      }
                                      alt=""
                                    />
                                    <div className="overlay-bx">
                                      <div className="overlay-icon text-white">
                                        <h5>Oogo Pay</h5>
                                        <p className="m-b10">
                                        {/* Coming Soon */}
                                        </p>
                                        <Iconimage />
                                        <a
                                          href="https://www.oogopay.com/"
                                          target="bank"
                                        >
                                          <i className="ti-arrow-top-right icon-bx-xs"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6">
                                <div className="dlab-box portfolio-box m-b30">
                                  <div
                                    className="dlab-media dlab-img-effect dlab-img-overlay1"
                                    data-tilt=""
                                  >
                                    {" "}
                                    <img
                                      src={
                                        "https://play-lh.googleusercontent.com/PEoKruc9j0Op8moNjLYN30gKGdRe0ok7IKXrQBfrSxreBKXuwxKL2K7ocLLG5W8Gm8Y=w2560-h1440-rw"
                                      }
                                      alt=""
                                    />
                                    <div className="overlay-bx">
                                      <div className="overlay-icon text-white">
                                        <h5>Oogo Pay</h5>
                                        <p className="m-b10">
                                        {/* Coming Soon */}
                                        </p>
                                        <Iconimage />
                                        <a
                                          href="https://www.oogopay.com/"
                                          target="bank"
                                        >
                                          <i className="ti-arrow-top-right icon-bx-xs"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6">
                                <div className="dlab-box portfolio-box m-b30">
                                  <div
                                    className="dlab-media dlab-img-effect dlab-img-overlay1"
                                    data-tilt=""
                                  >
                                    {" "}
                                    <img
                                      src={
                                        "https://play-lh.googleusercontent.com/PAa5V1weKn6LrL1oTaZy5lh-ITtGi3yuO1JizSudmxczcIDBE7DMAgoG3-nFfA8qwtU=w2560-h1440-rw"
                                      }
                                      alt=""
                                    />
                                    <div className="overlay-bx">
                                      <div className="overlay-icon text-white">
                                        <h5>Iqra Studio</h5>
                                        <p className="m-b10">
                                        Coming Soon
                                        </p>
                                        <Iconimage />
                                        <a
                                          href="https://www.iqrastudio.com/"
                                          target="bank"
                                        >
                                          <i className="ti-arrow-top-right icon-bx-xs"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                    {/* <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="dlab-box portfolio-box m-b30">
                          <div
                            className="dlab-media dlab-img-effect dlab-img-overlay1"
                            data-tilt=""
                          >
                            {" "}
                            <img
                              src={
                                "http://aqbmanagement.com/iqra/wp-content/uploads/2022/05/box-1.jpg"
                              }
                              alt=""
                            />
                            <div className="overlay-bx">
                              <div className="overlay-icon text-white">
                                <h5>Iqra Studio</h5>
                                <p className="m-b10">Coming Soon</p>
                                <Iconimage />
                                <a href="https://iqrastudio.com/" target="bank">
                                  <i className="ti-arrow-top-right icon-bx-xs"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="dlab-box portfolio-box m-b30">
                          <div
                            className="dlab-media dlab-img-effect dlab-img-overlay1"
                            data-tilt=""
                          >
                            {" "}
                            <img
                              src={
                                "http://aqbmanagement.com/iqra/wp-content/uploads/2022/05/2-3.jpg"
                              }
                              alt=""
                            />
                            <div className="overlay-bx">
                              <div className="overlay-icon text-white">
                                <h5>Iqra Studio</h5>
                                <p className="m-b10">Coming Soon</p>
                                <Iconimage />
                                <a href="https://iqrastudio.com/" target="bank">
                                  <i className="ti-arrow-top-right icon-bx-xs"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="dlab-box portfolio-box m-b30">
                          <div
                            className="dlab-media dlab-img-effect dlab-img-overlay1"
                            data-tilt=""
                          >
                            {" "}
                            <img
                              src={
                                "http://aqbmanagement.com/iqra/wp-content/uploads/2022/05/gold-variable-3-2.jpg"
                              }
                              alt=""
                            />
                            <div className="overlay-bx">
                              <div className="overlay-icon text-white">
                                <h5>Iqra Studio</h5>
                                <p className="m-b10">Coming Soon</p>
                                <Iconimage />
                                <a href="https://iqrastudio.com/" target="bank">
                                  <i className="ti-arrow-top-right icon-bx-xs"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
            {/* <!-- Pricing Table End --> */}
            {/* <!-- Testimonial --> */}
            {/* <div className="section-full content-inner-2 bg-img-fix overlay-primary gradient subscribe-bx" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <div className="container">
                                <form className="row text-white dezPlaceAni align-items-center dzSubscribe" action="script/mailchamp.php" method="post">
                                    <div className="col-lg-4  col-md-12">
                                        <h2 className="m-b0"><span className="font-weight-300">Subscribe to our</span><br /> Newsletter</h2>
                                    </div>
                                    <div className="col-lg-6 col-md-9 contact-form-bx">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <label>Your Email Address</label>
                                                <input name="dzEmail" required="required" type="email" className="form-control" placeholder="" />
                                                <div className="dzSubscribeMsg"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <button name="submit" value="Submit" type="submit" className="site-button button-md radius-xl white btn-block">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div> */}
            {/* <!-- Testimonial End --> */}
            {/* <!-- Contact Us --> */}
            <div
              id="contact"
              className="section-full content-inner-2 bg-white contact-form-bx"
              style={{
                backgroundImage: "url(" + bg16 + ")",
                backgroundSize: "100%",
              }}
            >
              <div className="container">
                <div className="section-head text-center">
                  <h2 className="text-uppercase">
                    <span className="font-weight-300">Contact </span> us
                  </h2>
                </div>
                <div className="dezPlaceAni">
                  <div className="dzFormMsg"></div>
                  <form method="post" className="" action="#">
                    <input type="hidden" value="Contact" name="dzToDo" />
                    <div className="row">
                      <div className="col-lg-4 col-md-5 col-sm-12">
                        <div className="form-group">
                          <div className="input-group">
                            <label>Your Name</label>
                            <input
                              name="dzName"
                              type="text"
                              required
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group">
                            <label>Your Email Address</label>
                            <input
                              name="dzEmail"
                              type="email"
                              className="form-control"
                              required
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group">
                            <label>Phone</label>
                            <input
                              name="dzOther[Phone]"
                              type="text"
                              required
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-7 col-sm-12">
                        <div className="form-group">
                          <div className="input-group">
                            <label>Your Message...</label>
                            <textarea
                              name="dzMessage"
                              rows="4"
                              className="form-control"
                              required
                              placeholder=""
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                        <button
                          name="submit"
                          type="button"
                          value="Submit"
                          className="site-button outline outline-2 radius-xl button-md m-t10"
                        >
                          Submit Now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* <!-- Contact Us End --> */}
          </div>
          {/* <!-- contact area END --> */}
        </div>

        <Footer2 />
      </>
    );
  }
}
export default Index4;
