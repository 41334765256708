import React,{Component} from 'react';			

export default class ThemeButton extends Component{
	render(){
		return(
			<>
				{/* <a href="https://1.envato.market/3zZ9y" className="bt-buy-now theme-btn"><i className="ti-shopping-cart"></i><span>Buy Now</span></a> */}
				<a href="https://wa.me/971505992759/?text=Hi"  className="bt-support-now theme-btn"><i className="ti-headphone-alt"></i><span>whatsapp us</span></a>
			</>
		)
	}	
}
